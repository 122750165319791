<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-6">
          <div class="card overflow-hidden">
              <div class="card-header">
                  <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle bg-light">
                          <img class="mx-auto d-block" src="@/assets/images/login/logo.png" height="70" />
                      </span>
                  </div>
              </div>
              <div class="card-body">
                  <b-overlay :show="loading">
                    <div class="p-2">
                      <b-alert :show="errorAlert" variant=" " dismissible fade class="text-white bg-danger">
                        <div class="iq-alert-icon">
                          <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">{{ errorMsg }}hh</div>
                      </b-alert>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(resetPassword)" @reset.prevent="reset" autocomplete="off">
                            <b-row>
                              <b-col sm="12" md="6">
                               <ValidationProvider name="New Password" vid="new_password" rules="required|min:6">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="new_password"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('forgot_password.newPassword')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-lock fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="new_password"
                                      type="password"
                                      v-model="formData.new_password"
                                      :placeholder="$t('forgot_password.newPassword')"
                                      autocomplete="new-password"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12" md="6">
                                 <ValidationProvider name="Confirm password" vid="password_confirmation" rules="required|confirmed:new_password">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="password_confirmation"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  <span style="font-weight:600; color: black;">{{$t('globalTrans.confirm_password')}}</span> <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group>
                                  <b-input-group-prepend>
                                        <span class="input-group-text"><i class="fa fa-lock fa-sm"></i></span>
                                    </b-input-group-prepend>
                                    <b-form-input
                                      id="password_confirmation"
                                      type="password"
                                      v-model="formData.password_confirmation"
                                      autocomplete="new-password"
                                      :placeholder="$t('globalTrans.confirm_password')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-input-group>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <br/>
                            <div class="row mt-3">
                              <div class="col-md-6 offset-md-3 text-center">
                                <button class="btn btn-success btn-block" type="submit">{{ $t('globalTrans.submit') }}</button>
                              </div>
                            </div>
                            <div class="mt-1 text-center">
                                <router-link to="/auth/login">{{ $t('globalTrans.back_to_login') }}</router-link>
                            </div>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </b-overlay>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { forgotPasswordResetPasswordApi } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  props: ['passwordResetCode'],
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    loading: false,
    formData: {
      password_reset_code: '',
      new_password: '',
      password_confirmation: ''
    },
    errorAlert: false,
    errorMsg: ''
  }),
  mounted () {
    this.formData.password_reset_code = this.passwordResetCode
    if (this.formData.password_reset_code === undefined || this.formData.password_reset_code === '') {
      this.$router.push('/auth/forgot-password/otp-sending')
    }
  },
  computed: {},
  methods: {
    async resetPassword () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, forgotPasswordResetPasswordApi, this.formData)
        if (result.success) {
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('forgot_password.successMsg'),
            color: '#D6E09B'
            })
          this.$router.push('/auth/login')
        } else {
          this.errorAlert = true
          this.errorMsg = this.$t('forgot_password.resetPasswordError')
          this.$refs.form.setErrors(result.errors)
        }
      } catch (error) {
        if (error) {
        }
        this.errorAlert = true
        this.errorMsg = this.$t('forgot_password.resetPasswordError')
      }

      this.loading = false
    }
  }
}
</script>

<style scoped>
        .label{font-weight: 600 !important;}
        .card{border: 0px; opacity: 0.8 !important}
        .card-header{background-color: #c7cef8 !important; border-bottom: 0px!important;}
        .btn-custom{background-color: #596DE1; color: #fff;}
        .form-control{height:30px !important; background-color:var(--iq-light) !important;
        }
        .form-group{margin-bottom:.1rem !important;}
        select{font-size: 12px !important;}
        .card-header{padding: 0.35rem 1.25rem !important;}
        .card-body {padding: 0.10rem 1.25rem !important;}
        .input-group-text {
        border: 1px solid #515252 !important;
        }

</style>
